import React from 'react'
import '../style/dashboard.css';
import Header2 from '../components/Header2'
import { FaArrowRightLong } from "react-icons/fa6";
import { AiFillGolden } from "react-icons/ai";
import { LuLayoutDashboard } from "react-icons/lu";
import { useNavigate } from 'react-router-dom';
import { BsClipboardData } from "react-icons/bs";


function IndustrialUseCase() {
    const navigate = useNavigate();

    const cardData = [
        { tittle: 'BAIP', body: 'Lorem Ipsum Dolor Sit Amet, Consectetur Ing Elit, Sed Do Eiusmod tempor Dunt Ut Labore Et Dolore Magna Aliqua. Ut Enim Ad Minin Veniam Ullamco Laboris.', icon: <AiFillGolden className="icon" /> },
        { tittle: 'Al Dashboard', body: 'AI Dashboard, short for Artificial Intelligence Dashboard, is a visual interface that provides real-time insights and analytics derived from artificial intelligence algorithms. It presents key performance indicators, trends, and predictions generated by AI models.', icon: <LuLayoutDashboard className="icon" /> },
        {
            tittle: 'GenAI: Talk to your DB',
            body: 'An industrial use case refers to a practical application or scenario where industrial processes or technologies are implemented to address specific challenges or achieve defined objectives.',
            icon: <BsClipboardData className="icon-hand" />,
            // path: '/GenAI_Talk_to_your_DB'
            // path: '/dashboard'
          },
    ]
    const onClickGo = (data) => {
        
    
        console.log('path', data);
    
        if (data === 'Al Dashboard') {
            window.open('/#/sales_marketing', '_blank');
            // navigate('/sales_marketing');
        } else if (data === 'BAIP') {
            navigate('/dashboard');
            // navigate('/dashboard1');
        }
         else if (data === 'GenAI: Talk to your DB') {
            // navigate('/dashboard');
            // navigate('/dashboard1');
            window.open('http://122.163.121.176:3004/Talk_to_your_SQL_DB','_blank')
        }
    };


    // const onClickGo = (data) => {
    //     console.log('path', data);
    //     if (data === 'Al Dashboard') {
    //         window.open('https://www.makemytrip.com/', '_blank');
    //     }
    //     else if
    //         (data === 'BAIP') {
    //         // window.open('https://www.ril.com/', '_blank');
            
    //         window.open('/KeyAccountManager', '_blank');
    //     }
    // }
    return (
        <div >
            <Header2 />
            <div className='conatiner-fluid w-100'>
                <div className='row w-100 h-100 p-4'>
                    {cardData.map((item, i) => (
                        <div className='col-12 col-md-6 col-lg-4 pl-4 mt-4' key={i}>
                            <div key={i} className='card-custom'>
                                <div className="card-header">
                                    <span className='tittle-text'>{item.tittle}</span>
                                    <span>{item.icon}</span>
                                </div>
                                <p className='pt-4'>{item.body}</p>
                                <div className="card-footer">
                                    <button className='button-arrow' onClick={(e)=>{ onClickGo(item.tittle)}}>
                                        <FaArrowRightLong className="logo-small" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default IndustrialUseCase