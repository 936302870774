// import { Box, useTheme, Grid, Typography } from "@mui/material";
// import { tokens } from "../../../theme";
// import PieCharts from "../../../components/charts/PieCharts";
// import DistributedColumnChart from "../../../components/charts/DistributedColumnChart";
// import React, { useEffect, useState } from 'react';
// import { apiService } from "../../../service/api-service";
// import SimpleBackdrop from "../../../scenes/global/Loader";
// import WorldMap from "./WorldMap";
// import GlobalCard from "../../../components/GlobalCard";
// import NotificationIcon from "../../../components/common/NotificationsIcon";
// import BarCharts from "../../../components/charts/Barchart";
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// //----------------------For filter -------------------------------
// import dayjs from 'dayjs';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import {
//   formatDays,
//   calculateGroupByAverage,
//   calculateAveragePercentage,
//   filterByDateAndClient,
//   filterByDateClientProduct,
//   calculateGroupByPercentage,
//   formatPersentage,
//   calculateAverage,
//   filterBenchMarkData,
//   filterByDate,
//   filterByDateProduct
// } from "../../../utils/GlobalFilters";
// import ColumnChart from "../../../components/charts/ColumnChart";
// import { useLocation } from 'react-router-dom';
// import Chatbot from "../../../components/chat-bot";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Tooltip from '@mui/material/Tooltip';
// import { faBell, faVolumeUp, faVolumeXmark } from '@fortawesome/free-solid-svg-icons';

const productionsales = () => {
 

 
     
};


export default productionsales;